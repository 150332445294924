<template>
    <zw-sidebar @shown="shown" :title="$t('common.button.import_history')">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">
                <zw-table ref="table"
                          tableName="import_history_log"
                          :items="myProvider"
                          :customSource="getThis()"
                          :fields="fields"
                          :filter="filter"
                          :selected="selected"
                          :selectedSource="selectedSource"
                          :actions-list="getActions()"
                          :sticky="false"
                          sticky-header="100%"
                >
                    <template #cell(status)="row">
                        <CBadge :color="statusClass(row.item.status)">{{ row.item.status }}</CBadge>
                    </template>
                    <template #cell(model_id)="row">
                        <a v-if="row.item.type=='customer'" href="#" @click.prevent="$root.$children[0].openModal('customer-modal', {customerId:row.item.model_id})">{{row.item.model_id}}</a>
                        <a v-else-if="row.item.type=='article'" href="#" @click.prevent="$root.$children[0].openModal('artivle-modal', {id:row.item.model_id})">{{row.item.model_id}}</a>
                        <template v-else>{{row.item.model_id}}</template>
                    </template>
                    <template #cell(data)="row">
                        <b-button size="sm"
                                  @click="$root.$children[0].openModal('json-modal', {data: row.item.data})"
                                  variant="info"
                                  class="mr-2"
                        >
                            <font-awesome-icon icon="eye"/>
                        </b-button>
                    </template>
                    <template #cell(actions)="row">
                    </template>
                </zw-table>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'

export default {
    name: 'ImportHistoryLogModal',
    mixins: [commonSave, commonTable],
    data() {
        return {
            loading: true,
            payload: {},
            saveFilters: false,
        }
    },
    computed: {},
    methods: {
        ...mapGetters('Import', ['getHistoryLogTable']),
        shown() {
            this.loading = false
        },
        getThis() {
            return this
        },
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'Import/fetchHistoryLogTable', 'getHistoryLogTable');
        },
        ctxAdditionalParams() {
            return {
                'history_id':  this.payload.id
            }
        },
        getActions() {
            return []
        },
        statusClass(status) {
            if (status == 'skipped') {
                return 'warning';
            } else if (status == 'created') {
                return 'success'
            } else if (status == 'updated') {
                return 'info'
            } else if (status == 'failed') {
                return 'danger'
            }
        },
    },
}
</script>